import React, {useEffect, useState} from 'react';
import {useSettingsContext} from './hooks';

export interface FeatureFlags {[key: string]: string};

interface Settings {
    featureFlags: FeatureFlags;
}

const defaultState: FeatureFlags = {};

export const FeatureFlagsContext = React.createContext(defaultState);

const FeatureFlagsProvider = ({children}: React.PropsWithChildren<Record<never, any>>) => {
    const [state, setState] = useState(defaultState);
    const settingsContext = useSettingsContext();
    const settings = (settingsContext.settings as unknown) as Settings;

    useEffect(() => {
        setState(settings.featureFlags);
    }, [settings, state, setState]);

    return <FeatureFlagsContext.Provider value={state}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsProvider;
