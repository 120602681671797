const loadingMessages = [
  "Please wait while I fetch the information you need.",
  "I'm searching for the right data. Just give me a moment.",
  "Hold on while I locate the relevant information for you.",
  "I apologize for the delay. I'm gathering the requested data.",
  "I'm working on finding the data you requested. Thank you for your patience.",
  "Searching the database...",
  "Please bear with me while I retrieve the necessary information.",
  "I'm on it! Just a few more seconds to retrieve the data for you.",
  "Thank you for waiting. I'm in the process of finding the data you seek.",
  "Hold tight! I'm working diligently to locate the desired information.",
];

const answerMessages = [
  "Please let me analyze that data. I'll respond when finished.",
  "Hmm, let me find out for you.",
  "Good question. I'll take a look.",
  "I'll find out shortly.",
];

const errorMessages = [
  "Sorry, I'm having trouble understanding you.",
  "Oops! Something went wrong.",
  "My apologies, I couldn't process your request.",
  "I'm sorry, but I didn't catch that.",
  "There seems to be an error. Please try again later.",
  "I'm afraid I don't have the information you're looking for.",
  "Sorry, I'm not able to assist with that right now.",
  "An error occurred. Please try again."
];

const greetingMessages = [
  "Hello! How can I assist you with your data analysis?",
  "Hi there! How can I help you today with your data analysis tasks?",
  "Greetings! How can I support you with your data analysis needs?",
  "Welcome! How can I assist you in analyzing your data?",
  "Hey! How can I help you make sense of your data today?",
  "Good day! How can I support you in your data analysis endeavors?",
  "Hi! How can I assist you in uncovering insights from your data?"
];

const stillWorkingMessages = [
  "Just a moment, I'm still analyzing the data.",
  "Please bear with me, I'm processing your request.",
  "I'm still working on your previous question, it may take a moment.",
  "Give me a few seconds, I'm crunching the numbers for you.",
  "I'm still in the process of analyzing the data, please wait.",
  "The analysis is underway, please hold on.",
  "Analyzing your data, it shouldn't take too long.",
  "I'm processing your data, thank you for your patience."
];

function getRandomMessage(messages: Array<string>): string {
  return messages[Math.floor(Math.random() * messages.length)];
}

export function getLoadingMessage(): string {
  return `${getRandomMessage(loadingMessages)}...`;
};

export function getAnswerMessage(): string {
  return getRandomMessage(answerMessages);
};

export function getErrorMessage(): string {
  return getRandomMessage(errorMessages);
};

export function getGreetingMessage(): string {
  return getRandomMessage(greetingMessages);
};

export function getStillWorkingMessage(): string {
  return getRandomMessage(stillWorkingMessages);
};
